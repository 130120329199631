import { ColourSwatch, ProductOptionKeys } from '../../../types/enterpriseTypes'
import { InStockLocation } from '../../generated/graphql'

interface Product {
  sku: string
  options: {
    key: string
    choices: {
      colour?: string
      key: string
      title: string
    }[]
  }[]
  content?: {
    key: string
    value: {
      stringValue: string
      __typename: 'ProductContentStringValue'
    }
  }[]
  variants: {
    title: string
    inStockLocations?: string[]
    choices: {
      key: string
      optionKey: string
    }[]
    content?: {
      key: string
      value: {
        stringValue: string
        __typename: 'ProductContentStringValue'
      }
    }[]
    inStock: boolean
    product?: {
      sku: string
      url?: string
    } | null
  }[]
}

export const getColour = (product: Product) =>
  `#${
    product?.content?.find((contentKey) => contentKey.key === 'colourHexCode')
      ?.value.stringValue
  }`

export const findOption = (product: Product, optionKey: string) => {
  return product.options.find((option) => option.key === optionKey)
}

export const getColourSwatchesOld = <ProductType extends Product>(
  product: ProductType | undefined,
  alternateProductKeysForSwatches?: string | undefined,
  departmentValue?: string | undefined,
): ColourSwatch[] => {
  if (!product || !product.options || !product?.variants) {
    return []
  }

  const mainProductSku = product.sku
  const optionKeys = alternateProductKeysForSwatches
    ? [
        ProductOptionKeys.COLOUR,
        ProductOptionKeys.SIZE,
        ProductOptionKeys.SHADE,
      ]
    : [ProductOptionKeys.COLOUR, ProductOptionKeys.SHADE]

  const optionType = optionKeys
    .map((key) => findOption(product, key))
    .find((option) => option)

  const getProductDepartment = (productContent) => {
    if (!alternateProductKeysForSwatches) {
      return null
    }
    return product.options.find(
      (option) =>
        option.key === ProductOptionKeys.SIZE &&
        productContent
          ?.find((contentKey) =>
            alternateProductKeysForSwatches.includes(contentKey.key),
          )
          ?.value.stringValue.toLowerCase() === departmentValue?.toLowerCase(),
    )
  }
  return (
    // TODO: CSBOM-405 to revisit the swatches logic implementation
    optionType?.choices.reduce(
      (swatches: NonNullable<ColourSwatch[]>, colourChoice) => {
        const currentVariant = product.variants.find(
          (variant) =>
            variant.inStock &&
            variant.choices?.find((choice) => choice.key === colourChoice.key),
        )
        const href = currentVariant?.product?.url
        const productContent = product?.variants?.find((variant) =>
          variant.content?.find((contentKey) =>
            contentKey.key.includes('colourHexCode'),
          ),
        )?.content
        const currentProduct = currentVariant && {
          sku: currentVariant.product?.sku,
          colour: getColour(currentVariant),
        }
        if (
          alternateProductKeysForSwatches &&
          colourChoice.colour === null &&
          getProductDepartment(productContent) &&
          (currentVariant?.inStockLocations?.includes(
            InStockLocation.Warehouse,
          ) ||
            currentVariant?.inStockLocations?.includes(InStockLocation.Store))
        ) {
          if (currentProduct && currentProduct.sku === mainProductSku) {
            colourChoice.colour = currentProduct.colour
          }
        }
        if (href && colourChoice.colour) {
          const swatch = {
            ...colourChoice,
            href,
            colour: colourChoice.colour,
            __typename: 'ColourSwatch',
          }

          if (colourChoice.colour === 'Multi') {
            swatches.unshift(swatch)
          } else {
            swatches.push(swatch)
          }
        }
        return swatches
      },
      [],
    ) || []
  )
}

export const getColourSwatchesPLP = <ProductType extends Product>(
  product:
    | (ProductType & {
        url?: string
        swatchImage?: {
          colour?: string
          title?: string
          key?: string
        }
      })
    | undefined,
): {
  colour: string
  href: string
  __typename: string
}[] => {
  if (!product) {
    return []
  }
  return [
    ...(product.swatchImage?.colour
      ? [
          {
            href: product.url,
            colour: product.swatchImage.colour.startsWith('#')
              ? product.swatchImage.colour
              : `#${product.swatchImage.colour}`,
            __typename: 'ColourSwatch',
          },
        ]
      : []),
    ...(product.linkedProducts?.reduce(
      (swatches: NonNullable<ColourSwatch[]>, product) => {
        const href = product.url
        const colour = product.swatchImage?.colour

        if (href && colour) {
          const normalizedColour = colour.startsWith('#')
            ? colour
            : `#${colour}`
          const swatch = {
            href,
            colour: normalizedColour,
            key: product.swatchImage?.key || '',
            title: product.swatchImage?.title || '',
            __typename: 'ColourSwatch',
          }

          // Logic to push "Multi" colors to the front
          if (normalizedColour === '#Multi') {
            swatches.unshift(swatch)
          } else {
            swatches.push(swatch)
          }
        }

        return swatches
      },
      [],
    ) || []),
  ]
}

export const getColourSwatches = <ProductType extends Product>(
  product: ProductType | undefined,
  alternateProductKeysForSwatches?: string,
  departmentValue?: string,
): ColourSwatch[] => {
  if (!product) return []

  let colourSwatches: ColourSwatch[] = []

  // First method: Use getColourSwatchesPLP if available
  // if (product?.linkedProducts || product?.swatchImage) {
  //   colourSwatches = getColourSwatchesPLP(product)
  //   if (colourSwatches.length > 0) {
  //     return colourSwatches
  //   }
  // }

  // Second method: Extract colours from product options
  // const colourOptionKeys = [ProductOptionKeys.COLOUR, ProductOptionKeys.SHADE]
  // colourSwatches = product.options.reduce((optionColourSwatches, option) => {
  //   if (!colourOptionKeys.includes(option.key)) {
  //     return optionColourSwatches
  //   }

  //   return option.choices.reduce((choiceColourSwatches, choice) => {
  //     if (choice.colour) {
  //       choiceColourSwatches.push({
  //         href: product.url,
  //         colour: choice.colour.startsWith('#')
  //           ? choice.colour
  //           : `#${choice.colour}`,
  //         __typename: 'ColourSwatch',
  //       })
  //     }
  //     return choiceColourSwatches
  //   }, [])
  // }, [])
  // if (colourSwatches.length > 0) {
  //   return colourSwatches
  // }

  // Third method: Fallback to getColourSwatchesOld
  colourSwatches = getColourSwatchesOld(
    product,
    alternateProductKeysForSwatches,
    departmentValue,
  )

  return colourSwatches
}
